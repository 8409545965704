import React from "react"
import Layout from "../components/layout"

export default function Home() {
  return (
    <Layout>
      <article class="content-item page">
        <h1>Introductie</h1>
        <div id="intro">
          <p>
            CRW Solutions levert kwalitatief hoogstaande oplossingen voor al uw
            uitdagingen op het gebied van (maatwerk)
            informatiesysteemontwikkeling. Of het nu gaat om consultancy,
            software ontwikkeling, technisch project management of het ophalen
            en beschrijven van systeemeisen, CRW Solutions staat voor een
            methodologische aanpak met een goed gedocumenteerd en bruikbaar
            eindresultaat.
          </p>
        </div>
        <p>&nbsp;</p>
        <h2>Expertise</h2>
        <p>&nbsp;</p>
        <p>
          CRW Solutions biedt de meest actuele expertise van technologien zoals
          .Net en J2EE met als fundament de volgende certificeringen:
        </p>
        <ul>
          <li>Certified Rational RUP Consultant</li>
          <li>Certified Rational OOAD Consultant</li>
          <li>IPMA Certified Project Manager</li>
          <li>ITIL v3 Foundation</li>
          <li>DNV-CIBIT Core Training IT-Architecture</li>
          <li>Bachelor Business Economics</li>
        </ul>
        <h2>&nbsp;</h2>
        <h2>Passie</h2>
        <p>&nbsp;</p>
        <p>
          Voor CRW Solutions is software ontwikkeling een absolute passie. CRW
          Solutions biedt u de professionaliteit om samen met u uw
          moderniseringsdoelstellingen te halen of te overtreffen!
        </p>
        <p>&nbsp;</p>
        <p>Met vriendelijke groet,</p>
        <p>
          <strong>
            Colin Wilmans
            <br />
          </strong>
        </p>
      </article>
    </Layout>
  )
}
